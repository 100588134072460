export const websiteData = [
  {
  id: "1",
  category: "Side Hustle Categories",
  subcategory: "Book Writing",
  name: "Authors AI",
  description: "Authors.ia is an artifical intelligence tool that can detect plot arcs, pacing, character development and more!",
  url: "https://authors.ai/",
  cost: "Paid",
  },
  {
  id: "2",
  category: "AI",
  subcategory: "Cool AI Stuff",
  name: "ChatGPT",
  description: "ChatGPT is a trained model which interacts in a conversational way. The dialogue format makes it possible for ChatGPT to answer your questions.",
  url: "https://chat.openai.com/chat",
  cost: "Subcription with Free Tier",
  },
  {
  id: "3",
  category: "AI",
  subcategory: "Text Summarization & Copy",
  name: "Copy.ai",
  description: "Copy.ai is an outstanding service company that leverages artificial intelligence to make copywriting easy by essentially writing your copy for you. It helps you generate written content within seconds and makes your draft look as authentic as possible.",
  url: "https://www.copy.ai/",
  cost: "Subcription with Free Tier",
  },
  {
  id: "4",
  category: "Visual Tools",
  subcategory: "Photo Editing and Tools",
  name: "Designify",
  description: "Designify combines visual AI's into one simple tool. Remove image backgrounds, add realistic shadows to objects and enhance colors by using artificial intelligence.",
  url: "https://www.designify.com/",
  cost: "Paid",
  },
  {
  id: "5",
  category: "Visual Tools",
  subcategory: "Video Making & Editing",
  name: "Kamua",
  description: "Kamua is a new artificial intelligence-powered video-editing platform.",
  url: "https://kamua.com/",
  cost: "Subscription",
  },
  {
  id: "6",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "Name Lix",
  description: "Namelix uses artificial intelligence to create a short, brandable business name.",
  url: "https://namelix.com/",
  cost: "Paid",
  },
  {
  id: "7",
  category: "Visual Tools",
  subcategory: "Photo Editing and Tools",
  name: "Skylum LuminarAI",
  description: "LuminarAI is an alternative to traditional photo editors in general. They offer a new approach to editing your images, powered by artificial intelligence.",
  url: "https://skylum.com/luminar-ai-b",
  cost: "Paid",
  },
  {
  id: "8",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "SocialStudio",
  description: "Social Studio is a personal Instagram assistant that will generate your content with Artificial Intelligence, allow you to design your posts, and schedule directly to Instagram in minutes.",
  url: "https://www.socialstudio.ai/",
  cost: "Paid",
  },
  {
  id: "9",
  category: "Work Help",
  subcategory: "Productivity Tools",
  name: "Synthesia",
  description: "Synthesia.io is an AI video creation platform developed for entrepreneurs, business owners, and corporates without the need for actors.",
  url: "https://www.synthesia.io/",
  cost: "Paid",
  },
  ];