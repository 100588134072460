import React from "react";

// import { websiteColorMap } from "../../data/categories";

const WebsiteTable = ({ data, onLinkSelect }) => {
  return (
    <div className="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
      <table className="table-fixed w-full divide-y divide-gray-200 whitespace-nowrap">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12">
              Name
            </th>
            <th className="hidden md:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-4/12">
              Description
            </th>
            {/* <th className="hidden xl:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12">
              Category
            </th> */}
            <th className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12">
              Subcategory
            </th>
            {/* <th className="hidden xl:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12">
              Cost
            </th>
            <th className="hidden xl:table-cell whitespace-pre-wrap px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12">
              Website
            </th> */}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((d) => (
            <tr key={d.id}>
              <td
                onClick={() => onLinkSelect(d.url)}
                className=" px-6 py-4 whitespace-pre-wrap text-sm"
              >
                {/* <a
                  href={d.url}
                  className="text-indigo-600 hover:text-indigo-900"
                  target="_blank"
                  rel="noreferrer"
                >
                  {d.name}
                </a> */}
                <button className="text-indigo-600 hover:text-indigo-900 text-left">
                  {d.name}
                </button>
              </td>
              <td className="hidden md:table-cell px-6 py-4 whitespace-pre-wrap text-sm text-gray-500">
                {d.description}
              </td>
              {/* <td className="hidden xl:table-cell px-6 py-4 whitespace-pre-wrap text-sm text-gray-500">
                <span
                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    websiteColorMap[d.category]
                  }`}
                >
                  {d.category}
                </span>
              </td> */}
              <td className=" px-6 py-4 whitespace-pre-wrap text-sm text-gray-500">
                {d.subcategory}
              </td>
              {/* <td className="hidden xl:table-cell px-6 py-4 whitespace-pre-wrap text-sm text-gray-500">
                {d.cost}
              </td>
              <td onClick={() => onLinkSelect(d.url)} className="hidden xl:table-cell px-6 py-4 whitespace-pre-wrap text-sm">
              <button
                  
                  className="text-indigo-600 hover:text-indigo-900"

                >
                  Website
                </button>                
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      {!data.length && (
        <div className="p-5 text-center text-gray-700 border-t border-gray-200">
          No results
        </div>
      )}
    </div>
  );
};

export default WebsiteTable;
